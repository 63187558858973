import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { userAPI } from "./userAPI";
import { tokenStorage } from "./tokenStorage";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();


// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [isManager,setIsManager] = useState(false);
  const {t} = useTranslation();

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.

  
  const authResolver = useCallback(({ username,manager, token: _token,refreshToken: _refreshToken }) => {
    setUser(username);
    setToken(_token);
    setRefreshToken(_refreshToken);
    setIsAuthenticated(true);
    setError(null);
    setIsManager(manager);
    tokenStorage.storeIsManager(manager);
    tokenStorage.storeUserName(username);
    tokenStorage.store(_token);
    tokenStorage.storeRefresh(_refreshToken);
  }, []);
  const refreshTokenResolver=useCallback(({token: _token,refreshToken: _refreshToken}) => {
    setIsAuthenticated(true);
    setError(null);
    setToken(_token);
    setRefreshToken(_refreshToken);
    setUser(tokenStorage?.getUserName() || "");
    setIsManager(Boolean(tokenStorage?.getIsManager()) );
    tokenStorage.storeRefresh(_refreshToken);
    tokenStorage.store(_token);
    },[])

  useEffect(() => {
    const _token = tokenStorage.get();
    const _refreshToken= tokenStorage.getRefresh();   
    if (_token && _refreshToken) {
      userAPI
        .initilize(_refreshToken)
        .then(refreshTokenResolver)
        .catch((error) => {
          const status = error?.response?.status
          if (status === 401) {
            tokenStorage.removeAll();
            
            
          }
        })
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, [refreshTokenResolver]);

  
  


  

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = useCallback(
    (values) => {
      setIsLoading(true);
      userAPI
        .login(values)
        .then(authResolver)
        .catch((_error) => {
          setError(_error?.response?.data?.message ||t("error_in_Login") );
          toast.error(_error?.response?.data?.message || t("error_in_Login"))


        })
        .finally(() => setIsLoading(false));
    },
    [authResolver , t]
  );



  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  const signup = useCallback(
    (body) => {
      setIsLoading(true);
      userAPI
        .signup(body)
        .then(authResolver)
        .catch((_error) => {
          toast.error(_error?.response?.data?.message || "ERROR IN SIGNUP")
          setError(_error?.response?.data?.message || "ERROR IN SIGNUP");
        })
        .finally(() => setIsLoading(false));
    },
    [authResolver]
  );


  // Call the logout endpoint and then remove the user
  // from the state.
  const NotAuthlogout = useCallback(() => {
   
    setUser(null);
    setToken("");
    setRefreshToken("");
    setIsAuthenticated(false);
    tokenStorage.removeAll();
    setError(null);
    setIsManager(false);

}, []);

    // Call the logout endpoint and then remove the user
  // from the state.
  const logout = useCallback(() => {
    userAPI
      .logout(token,refreshToken)
      .then(() => {
        setUser(null);
        setToken("");
        setRefreshToken("");
        setIsAuthenticated(false);
        setError(null);
        setIsManager(false);
        tokenStorage.removeAll();
      })
      .catch((_error) =>{

          NotAuthlogout();
      })
  }, [token,refreshToken,NotAuthlogout]);


 
  function clearError() {
    setError(null);
  }

  function updateUserInfo(newValues) {
    setUser((prevUser) => ({ ...prevUser, ...newValues }));
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      token,
      refreshToken,
      isAuthenticated,
      isLoading,
      loadingInitial,
      error,
      isManager,
      NotAuthlogout,
      login,
      signup,
      logout,
      clearError,
      updateUserInfo,
      refreshTokenResolver
    }),
    [user, token, isAuthenticated, isLoading, loadingInitial,refreshToken,isManager, error,NotAuthlogout,refreshTokenResolver, logout, login, signup]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
