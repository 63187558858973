import useAuth from "auth/useAuth"

export const useGetNavRoutes=()=>{
    const {isManager} = useAuth();
    let navRoutes= [
       
      
        {
    
            path:"/",
            
            title:"home",
            
        },
       
    ]

    if(isManager){
        navRoutes=[...navRoutes,
           
            {
    
            path:"/governmental_entities",
            
            title:"governmental_entities",
            
        },
        {
            
            path:"/governmental_services",
            
            title:"governmental_services",
            
        },
    
    
        {
    
            path:"/roles_and_privileges",
            
            title:"roles_and_privileges",
            
        },]
    }else {
        navRoutes=[...navRoutes ,
            {
            
                path:"/governmental_services",
                
                title:"governmental_services",
                
            },
        ]
    }
    return navRoutes;

}

