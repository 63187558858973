import { useLocation, matchPath } from "react-router-dom";
import { useEffect } from "react";
import { Routes } from "./Routes";
import { useTranslation } from "react-i18next";

export const useDocumentRename = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    let titleKey = null;
    Object.keys(Routes).forEach((key) => {
      if (titleKey === null && matchPath(Routes[key].url, pathname)) {
        titleKey = Routes[key].title;
      }
    });
    if (titleKey) {
      document.title = `${t("brand")} | ${t(titleKey)}`;
    } else {
      document.title = t("brand");
    }
  }, [pathname, t]);
};
