import React from "react";
import logo from 'assets/images/logo/BigLogo.png'
import colors from "assets/scss/colors.module.scss";

import classes from "./LoadingPage.module.scss";
import { PropagateLoader } from "react-spinners";

export const LoadingPage = () => {
  return (
    <div className={classes.loading_page}>
      <img src={logo} alt="logo" />
      <PropagateLoader size={20} color={colors.button_color} />
    </div>
  );
};
