import React, { useState } from 'react'
import logo from 'assets/images/logo/BigLogo.png'
import classes from './MainNavBar.module.scss'
import { useGetNavRoutes } from 'utility/navigation/navRoutes';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover, PopoverBody } from 'reactstrap';
import {GiHamburgerMenu} from 'react-icons/gi'
import MobileNav from './mobile/MobileNav';
import { useWindowSize } from 'hooks/useWindowSize';
import useAuth from 'auth/useAuth';

export default function MainNavBar() {
  const {t,i18n} = useTranslation();
  const [open,setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const {isAuthenticated, logout}=useAuth();
  const navRoutes=useGetNavRoutes();

  const handleClick=()=>{
    logout();
    setPopoverOpen(false);
  }


    const handleLanguageChange=()=>{
  
      if(i18n.language === "en"){
        i18n.changeLanguage("ar");
      }else {
        i18n.changeLanguage("en")
      }
    }

    const {width}=useWindowSize();
    const stillBig=React.useMemo(()=>{
      if(width > 900){
        return true
      }else return false
    },[width])

    const {user} = useAuth();
      return (
    <>
    <div className={classes.wrapper}>
        
    <div className={isAuthenticated ? classes.container : classes.notAuthContainer}>
      <img src={logo} alt="logo" className={classes.logo}/>
       {
             isAuthenticated &&
             <div className={classes.subContainer} > 


                <h5 onClick={()=>handleLanguageChange()} style={{fontWeight:"600"}} className={classes.language}>
                    {i18n.language ==="en"?t("العربية"):t("english")}
                </h5>
                  <h5 className={classes.language} style={{fontWeight:"600"}} id="popover"  color="primary" onClick={togglePopover}>
                    {user? user :  t("user")}
                  </h5>
             { !stillBig &&<GiHamburgerMenu size={25} onClick={()=>setOpen(true)} style={{cursor:"pointer"}} color='#2A3043'/>}
                  <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target="popover" toggle={togglePopover}>
                    <PopoverBody>
                      <Button onClick={handleClick} color='primary'>

                        {t("logout")}
                      </Button>
                      </PopoverBody>
                  </Popover>






                

            </div>
   
       }


    </div>
    
    </div>
    {
        isAuthenticated && stillBig && <div className={classes.subNavBar}>
            {
              navRoutes.map(ele=><NavLink key={ele.path}  className={classes.Link} to={ele.path}> {t(ele.title)}</NavLink>)
            }
         </div>
    }
    <MobileNav open={open} setOpen={setOpen}/>
   
    </>
  )
}
