import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom';
import {  Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import classes from './MobileNav.module.scss'
import logo from 'assets/images/logo/BigLogo.png'
import { useGetNavRoutes } from 'utility/navigation/navRoutes';

export default function MobileNav({open,setOpen}) {
    const {t,i18n} = useTranslation();
    const navRoutes=useGetNavRoutes();
    const toggleOffcanvas = () => setOpen(prev=>!prev);

    const onExitedOffcanvas = () => setOpen(false);
    
  return (
    <Offcanvas  style={{backgroundColor:"#004D78"}}
    isOpen={open} direction={i18n.language === "ar" ? "start" : "end"} toggle={toggleOffcanvas} fade={true} onExited={onExitedOffcanvas} backdrop={true}>
        <OffcanvasHeader style={{color:"#FFF"}} toggle={toggleOffcanvas}>
          
                <img  width="150px" src={logo} alt="logo"/>
          
        </OffcanvasHeader>
        <OffcanvasBody>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"1rem"}}>
            {

                    navRoutes.map(ele=><NavLink onClick={onExitedOffcanvas} key={ele.path} className={classes.link}   to={ele.path}> {t(ele.title)}</NavLink>)
                }
                </div>
                    
        </OffcanvasBody>
    </Offcanvas>
  )
}
