import React from "react";
import { lazy } from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { useUrlCorrection } from "./utility/navigation/useUrlCorrection";
import { useDocumentRename } from "./utility/navigation/useDocumentRename";
import { useScrollToTop } from "./utility/navigation/useScrollToTop";
import { Routes as RoutesConfigs } from "./utility/navigation/Routes";
import MainNavBar from "views/components/mainNavBar/MainNavBar";
import AuthElement from "utility/navigation/AuthElement";
// import AuthElement from "./utility/navigation/AuthElement";


const NotFoundPage = lazy(() => import("./views/pages/404/NotFoundPage"));

const AppRouter = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};
export default AppRouter;

function AppRoutes() {
  useUrlCorrection();
  useDocumentRename();
  useScrollToTop();


  return (
    <>

    <MainNavBar/>
    <Routes>
      {Object.keys(RoutesConfigs).map((key) => {
        const { url, component: Component,isPrivate,isManager

              } = RoutesConfigs[key];

        return (
          <Route
            key={url}
            path={url}
            element={
              isPrivate ? (
                <AuthElement isManager={isManager}>
                  <Component />
                </AuthElement>
              ) : (
                <Component />
              )
            }
          />
        );
      })}
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
    </>
  );
}
