import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CustomToastContainer = (props) => {

  

  return (
    <ToastContainer
      closeButton={false}
      position="top-center"
      autoClose={1000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      {...props}
    />
  );
};

export default CustomToastContainer;
