import { lazy } from "react";

//title: Used for document title -- (Translated)
export const Routes = {
  home: {
    url: "/",
    title: "home",
    isPrivate:true,
    component: lazy(() => import("../../views/pages/home/HomePage")),
    
  },
  login: {
    url: "/login",
    title: "login",
    component: lazy(() => import("../../views/pages/authentication/login/LoginPage")),
  },
  // register: {
  //   url: "/register",
  //   title: "register",
  //   component: lazy(() => import("../../views/pages/authentication/register/RegisterPage")),
  // },
  // email_verification: {
  //   url: "/email_verification",
  //   title: "email_verification",
  //   component: lazy(() => import("../../views/pages/authentication/emailVerification/EmailVerificationPage")),
  // },
  reset_password: {
    url: "/reset_password",
    title: "reset_password",
    component: lazy(() => import("../../views/pages/authentication/forgot_password/ForgotPasswordPage")),
  },
  governmental_services: {
    url: "/governmental_services",
    title: "governmental_services",
    isPrivate:true,
    component: lazy(() => import("../../views/pages/governmental_services/GovernmentalServicesPage")),
  },
  governmental_entities: {
    url: "/governmental_entities",
    title: "governmental_entities",
    isPrivate:true,
    isManager:true,
    component: lazy(() => import("../../views/pages/governmental_entities/GovernmentalEntitiesPage")),
  },
  accounts: {
    url: "/roles_and_privileges",
    title: "accounts",
    isPrivate:true,
    isManager:true,
    component: lazy(() => import("../../views/pages/accounts/AccountsPage")),
  },
  not_authorized: {
    url: "/not_authorized",
    title: "not_authorized",

    component: lazy(() => import("../../views/pages/notAuthorized/NotAuthorizedPage")),
    
  },
 
  
};
