import React , {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Router from "./Router";
import reportWebVitals from './reportWebVitals';
import './customColors.scss';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Layout } from './utility/context/Layout';
import { LoadingPage } from 'views/pages/LoadingPage/LoadingPage';
import CustomToastContainer from 'views/components/Toast/ToastContainer';
import { AuthProvider } from 'auth/useAuth';
import { QueryClient, QueryClientProvider } from "react-query";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // retryDelay: 2000,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<LoadingPage />}>
    <AuthProvider>

      <QueryClientProvider client={queryClient}>

      <Layout>

        <Router/>
        <CustomToastContainer />
      </Layout>

      </QueryClientProvider>


    </AuthProvider>


  </Suspense>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
