import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";
import AuthorizationRoute from "./AuthorizationRoute";

const RequireAuth = ({ children, redirectTo,isManager, fallback = "Loading ..." }) => {
  const { isAuthenticated, loadingInitial } = useAuth();
  
  if (loadingInitial) {
    return fallback;
  }
  return isAuthenticated ? <AuthorizationRoute children={children} isManager={isManager}/> : <Navigate to={redirectTo} />;
};

export default RequireAuth;
