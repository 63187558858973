import _axios from "axios";
import i18next from "i18next";
import { buildFormData } from "api/helpers/buildFormData";
import { prefixContext } from "api/config";
import { baseURL } from "api/config";

const API = {
  // GET_CURRENT_USER: `/api/get_user`,
  LOGIN: `/auth/login`,
  LOGOUT: `/auth/logout`,
  // REGISTER: `/api/register`,
  REFRESH_TOKEN:`/auth/refresh`
};

const getAxiosInstance = (token) =>
  _axios.create({
    headers: {
      language: i18next.language || "en",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    baseURL:`${baseURL}${prefixContext}`
     
  });

const getCurrentUser = async (token) => {
  const axios = getAxiosInstance(token);
  const response = await axios.get(API.GET_CURRENT_USER);
  return response?.data?.data?.user;
};

const initilize= async (refresh_token) => {
  const data ={refreshToken:refresh_token}
  const formData = new FormData();
  buildFormData(formData, data);
 const axios = getAxiosInstance();
 const response = await axios.post(API.REFRESH_TOKEN,formData);
 return response?.data; 
}




const login = async (values) => {
  const formData = new FormData();
  buildFormData(formData, values);
  const axios = getAxiosInstance();
  const response = await axios.post(API.LOGIN, formData);
  return response?.data;
};

const refreshToken= async (refreshToken) => {
  const data ={refreshToken:refreshToken}
  const formData = new FormData();
  buildFormData(formData, data);
  

  const axios = getAxiosInstance();
  const response = await axios.post(API.REFRESH_TOKEN,formData);
  return response?.data;
}

const loginViaPhone = async (phone, password) => {
  const axios = getAxiosInstance();
  const response = await axios.post(API.LOGIN, { phone, password });
  return response?.data?.data;
};

const logout = async (token,refresh_Token) => {
  const axios = getAxiosInstance(token);
  const data ={refreshToken:refresh_Token}
  const formData = new FormData();
  buildFormData(formData, data);
  const response = await axios.post(API.LOGOUT,formData);
  return response?.data;
};

const signup = async (body) => {
  const axios = getAxiosInstance();
  const response = await axios.post(API.REGISTER, body);
  return response?.data?.data;
};

export const userAPI = {
  getCurrentUser,
  login,
  loginViaPhone,
  logout,
  signup,
  initilize,
  refreshToken
};
