
const TOKEN_KEY = "SANAD_ROLES_AND_PRIVILEGES_TOKEN";
const REFRESH_KEY="SANAD_ROLES_AND_PRIVILEGES_REFRESH_TOKEN";
const REFRESH_KEY_EXPIRING_DATE="SANAD_ROLES_AND_PRIVILEGES_REFRESH_TOKEN_EXPIRING_DATE";
const TOKEN_KEY_EXPIRING_DATE="SANAD_ROLES_AND_PRIVILEGES_TOKEN_KEY_EXPIRING_DATE";
const USERNAME="SANAD_ROLES_AND_PRIVILEGES_USER_NAME";
const ISMANAGER="SANAD_ROLES_AND_PRIVILEGES_IS_MANAGER";




const storeRefresh = (value) => {
  localStorage.setItem(REFRESH_KEY, value);
};

const removeRefresh = () => {
  localStorage.removeItem(REFRESH_KEY);
};

const getRefreshDate = () => {
  return localStorage.getItem(REFRESH_KEY_EXPIRING_DATE);
};
const storeRefreshDate = (value) => {
  localStorage.setItem(REFRESH_KEY_EXPIRING_DATE, value);
};

const removeRefreshDate = () => {
  localStorage.removeItem(REFRESH_KEY_EXPIRING_DATE);
};
const getTokenDate = () => {
  return localStorage.getItem(TOKEN_KEY_EXPIRING_DATE);
};
const storeTokenDate = (value) => {
  localStorage.setItem(TOKEN_KEY_EXPIRING_DATE, value);
};

const removeTokenDate = () => {
  localStorage.removeItem(TOKEN_KEY_EXPIRING_DATE);
};

const getRefresh = () => {
  return   localStorage.getItem(REFRESH_KEY);
};



const store = (value) => {
  localStorage.setItem(TOKEN_KEY, value);
};

const remove = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const get = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const storeUserName = (value) => {
  localStorage.setItem(USERNAME, value);
};

const removeUserName = () => {
  localStorage.removeItem(USERNAME);
};

const getUserName = () => {
  return localStorage.getItem(USERNAME);
};
const storeIsManager = (value) => {
  localStorage.setItem(ISMANAGER, value);
};

const removeIsManager = () => {
  localStorage.removeItem(ISMANAGER);
};

const getIsManager = () => {
  return JSON.parse(localStorage.getItem(ISMANAGER));
};
const removeAll=()=>{
  remove();
  removeIsManager();
  removeRefresh();
  removeUserName();
}




export const tokenStorage = {
  store,
  remove,
  get,
  storeRefresh,
  removeRefresh,
  getRefresh,
  getRefreshDate,
  storeRefreshDate,
  removeRefreshDate,
  getTokenDate,
  storeTokenDate,
  removeTokenDate,
  storeUserName,
removeUserName,
getUserName,
storeIsManager,
removeIsManager,
getIsManager,
removeAll
};
