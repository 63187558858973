import React from 'react'
import useAuth from "./useAuth";
import { Navigate } from 'react-router-dom';

export default function AuthorizationRoute({isManager,children}) {
        const {isManager:manager}=useAuth();
        
        const isAuthorized= (Boolean(manager) === true) || isManager === undefined ;
  return (
        isAuthorized ? children : <Navigate to="/not_authorized"/>

    )
}
