import { Routes } from "./Routes";
import RequireAuth from "../../auth/RequireAuth";
import { LoadingPage } from "views/pages/LoadingPage/LoadingPage";

const AuthElement = ({ children,isManager, ...props }) => {
  return (
    <RequireAuth
      redirectTo={Routes.login.url}
      fallback={<LoadingPage />}
      isManager={isManager}
      
      {...props}
    >
      {children}
    </RequireAuth>
  );
};

export default AuthElement;
